import { render, renderToString } from '@wordpress/element';

import './css/swiper.min.css'; // Swiper CSS
import './style.scss';
import Slider from './Slider';
import Style from './Style';
import sliderOptions from './sliderOptions';
import { childPositionSet } from './utils/functions';

// Content Slider
document.addEventListener('DOMContentLoaded', () => {
	const allSlider = document.querySelectorAll('.wp-block-csb-content-slider-block');
	Object.values(allSlider).map(slider => {
		const attributes = JSON.parse(slider.dataset.attributes);
		const { cId, slides = [] } = attributes;

		// Slider in wrapper
		const csbSliderWrapper = document.querySelector(`#${slider.id} .csbSliderWrapper`);
		csbSliderWrapper.innerHTML = renderToString(<Slider attributes={attributes} />);

		// Render Style
		render(<Style attributes={attributes} clientId={cId} />, document.querySelector(`#${slider.id} .csbContentSliderStyle`));

		// Initialize Slider
		new Swiper(`#${slider.id} .csbContentSlider`, sliderOptions(attributes, 0));


		// position to childPosition start
		slides.map((item, index) => {
			const { position, childPositions = [{ left: 5.8, top: 11 }, { left: 5.8, top: 22 }, { left: 5.8, top: 33 }] } = item;
			let positionPlaceholder = position;

			// Select Slider Content and Child
			const sliderContent = document.querySelector(`#${slider.id} .csbContentSlider .slide-${index}:not(.swiper-slide-duplicate) .sliderContent`);

			// Get some tool for array position
			const childPositionsForOld = (i, top, left) => {
				childPositions[i]['top'] = parseFloat(top);
				childPositions[i]['left'] = parseFloat(left);
			}
			childPositionSet(positionPlaceholder, sliderContent, childPositionsForOld, false);
		});
		// position to childPosition end

		slider?.removeAttribute('data-attributes');
	});
});